import { default as indexmleTOlCo2ZMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/cases-de-sucesso/index.vue?macro=true";
import { default as conta_45bancariarWadMR5nObMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/conta-bancaria.vue?macro=true";
import { default as dados_45documentoXkM7FJxO5cMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/dados-documento.vue?macro=true";
import { default as dados_45patrimoniais95L0UHHdJ7Meta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/dados-patrimoniais.vue?macro=true";
import { default as declaracoesySQBtPjTIRMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/declaracoes.vue?macro=true";
import { default as documentosbg6gKRMd2SMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/documentos.vue?macro=true";
import { default as endereco6QemZHinFpMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/endereco.vue?macro=true";
import { default as indexdIoxO4uvcXMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/index.vue?macro=true";
import { default as informacoes_45pessoaisfwu15D5CCzMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/informacoes-pessoais.vue?macro=true";
import { default as _91partner_93mUPA6rCYvQMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/crie-sua-conta/[partner].vue?macro=true";
import { default as _91slug_93xoGpJEzj3iMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/crie-sua-conta/b2b/[slug].vue?macro=true";
import { default as _91slug_934mme6tQNvTMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/crie-sua-conta/convite/[slug].vue?macro=true";
import { default as index1Zpa1nNStHMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/index.vue?macro=true";
import { default as confirmacaorLocRdXijHMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/confirmacao.vue?macro=true";
import { default as detalhes9BCZivxgWqMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/detalhes.vue?macro=true";
import { default as enquadramentoagdQrcW8OaMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/enquadramento.vue?macro=true";
import { default as indexiZa2nQKU7ZMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/index.vue?macro=true";
import { default as confirmar_45contaaKa0B0VJVBMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/inicio/confirmar-conta.vue?macro=true";
import { default as criar_45contatpSp0XvEZmMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/inicio/criar-conta.vue?macro=true";
import { default as indexwQEkJeymYRMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/inicio/index.vue?macro=true";
import { default as inicioh0IEDAityJMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/inicio.vue?macro=true";
import { default as _91indicationCode_93pSe4ctxXV6Meta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/novo-investidor/[indicationCode].vue?macro=true";
import { default as _91operationId_93et6i49ZT1NMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/oportunidades/[operationId].vue?macro=true";
import { default as finalizadasbdRwlNPLyvMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/oportunidades/finalizadas.vue?macro=true";
import { default as historicoOAfcRBy5IBMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/oportunidades/historico.vue?macro=true";
import { default as indexmhb1F9TR84Meta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/oportunidades/index.vue?macro=true";
import { default as _91operationId_93wnaFKCCDySMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/oportunidades/parceiro/[operationId].vue?macro=true";
import { default as indexzgkWs9teFgMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/originadoras/index.vue?macro=true";
import { default as _91slug_936ZJOEzVhxQMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/ajuda/[slug].vue?macro=true";
import { default as index5aOWQWmNyPMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/ajuda/index.vue?macro=true";
import { default as _91operationId_93bMKSAPQXrsMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/detalhes-investimento/[operationId].vue?macro=true";
import { default as index2kk3qUnr3xMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/index.vue?macro=true";
import { default as index0P5CpZQVvlMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/indicacao/index.vue?macro=true";
import { default as index9fGNIww1VrMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/ir/index.vue?macro=true";
import { default as index0CVsCkGLvpMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/pendentes/index.vue?macro=true";
import { default as indexjvyJyuS2QxMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/alterar-email/index.vue?macro=true";
import { default as indexFP7aogqixkMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/alterar-senha/index.vue?macro=true";
import { default as indexUQvV4ra46BMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/alterar-telefone/index.vue?macro=true";
import { default as adicionarw8ssa0CLOoMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/chave-pix/adicionar.vue?macro=true";
import { default as indexQqWS4bezM5Meta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/chave-pix/index.vue?macro=true";
import { default as editarHQBKFUggjPMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/[id]/editar.vue?macro=true";
import { default as adicionarMrHGqbz5riMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/adicionar.vue?macro=true";
import { default as indexVlWSJu2CJxMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/index.vue?macro=true";
import { default as index1Ik7GdNuDpMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/dados-patrimoniais/index.vue?macro=true";
import { default as index81jC0vlSrTMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/dados-pessoais/index.vue?macro=true";
import { default as indexHO7j90hHJUMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/desativar-conta/index.vue?macro=true";
import { default as indexUDDNk7qAuHMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/documentos/index.vue?macro=true";
import { default as indexaljlzCyntLMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/endereco/index.vue?macro=true";
import { default as indexf4PjRzONlDMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/index.vue?macro=true";
import { default as _91assetSymbol_93tgLKiPA0BGMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/wallet/[assetSymbol].vue?macro=true";
import { default as depositoOs5A1rL3w6Meta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/wallet/deposito.vue?macro=true";
import { default as index9TVTykzdUdMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/wallet/index.vue?macro=true";
import { default as resgatemhKenKNZjRMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/wallet/resgate.vue?macro=true";
import { default as index6SXqo7ef9bMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/parceiros/index.vue?macro=true";
import { default as index7h0VZMdpX6Meta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/politica-de-privacidade/index.vue?macro=true";
import { default as indexUS5oF0UzPHMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/quem-somos/index.vue?macro=true";
import { default as index53FOwv14JiMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/remocao-de-conta/index.vue?macro=true";
import { default as _91signupCode_93nIl58PbsN0Meta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/seja-investidor/[signupCode].vue?macro=true";
import { default as indexZBfm4VZT0aMeta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/termos-de-uso/index.vue?macro=true";
import { default as indexNaqdd6cZB9Meta } from "/codebuild/output/src3468967218/src/hcp-web-platform/pages/tokenizacao/index.vue?macro=true";
export default [
  {
    name: indexmleTOlCo2ZMeta?.name ?? "cases-de-sucesso",
    path: indexmleTOlCo2ZMeta?.path ?? "/cases-de-sucesso",
    meta: indexmleTOlCo2ZMeta || {},
    alias: indexmleTOlCo2ZMeta?.alias || [],
    redirect: indexmleTOlCo2ZMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/cases-de-sucesso/index.vue").then(m => m.default || m)
  },
  {
    name: conta_45bancariarWadMR5nObMeta?.name ?? "completar-cadastro-conta-bancaria",
    path: conta_45bancariarWadMR5nObMeta?.path ?? "/completar-cadastro/conta-bancaria",
    meta: conta_45bancariarWadMR5nObMeta || {},
    alias: conta_45bancariarWadMR5nObMeta?.alias || [],
    redirect: conta_45bancariarWadMR5nObMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/conta-bancaria.vue").then(m => m.default || m)
  },
  {
    name: dados_45documentoXkM7FJxO5cMeta?.name ?? "completar-cadastro-dados-documento",
    path: dados_45documentoXkM7FJxO5cMeta?.path ?? "/completar-cadastro/dados-documento",
    meta: dados_45documentoXkM7FJxO5cMeta || {},
    alias: dados_45documentoXkM7FJxO5cMeta?.alias || [],
    redirect: dados_45documentoXkM7FJxO5cMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/dados-documento.vue").then(m => m.default || m)
  },
  {
    name: dados_45patrimoniais95L0UHHdJ7Meta?.name ?? "completar-cadastro-dados-patrimoniais",
    path: dados_45patrimoniais95L0UHHdJ7Meta?.path ?? "/completar-cadastro/dados-patrimoniais",
    meta: dados_45patrimoniais95L0UHHdJ7Meta || {},
    alias: dados_45patrimoniais95L0UHHdJ7Meta?.alias || [],
    redirect: dados_45patrimoniais95L0UHHdJ7Meta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/dados-patrimoniais.vue").then(m => m.default || m)
  },
  {
    name: declaracoesySQBtPjTIRMeta?.name ?? "completar-cadastro-declaracoes",
    path: declaracoesySQBtPjTIRMeta?.path ?? "/completar-cadastro/declaracoes",
    meta: declaracoesySQBtPjTIRMeta || {},
    alias: declaracoesySQBtPjTIRMeta?.alias || [],
    redirect: declaracoesySQBtPjTIRMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/declaracoes.vue").then(m => m.default || m)
  },
  {
    name: documentosbg6gKRMd2SMeta?.name ?? "completar-cadastro-documentos",
    path: documentosbg6gKRMd2SMeta?.path ?? "/completar-cadastro/documentos",
    meta: documentosbg6gKRMd2SMeta || {},
    alias: documentosbg6gKRMd2SMeta?.alias || [],
    redirect: documentosbg6gKRMd2SMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/documentos.vue").then(m => m.default || m)
  },
  {
    name: endereco6QemZHinFpMeta?.name ?? "completar-cadastro-endereco",
    path: endereco6QemZHinFpMeta?.path ?? "/completar-cadastro/endereco",
    meta: endereco6QemZHinFpMeta || {},
    alias: endereco6QemZHinFpMeta?.alias || [],
    redirect: endereco6QemZHinFpMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/endereco.vue").then(m => m.default || m)
  },
  {
    name: indexdIoxO4uvcXMeta?.name ?? "completar-cadastro",
    path: indexdIoxO4uvcXMeta?.path ?? "/completar-cadastro",
    meta: indexdIoxO4uvcXMeta || {},
    alias: indexdIoxO4uvcXMeta?.alias || [],
    redirect: indexdIoxO4uvcXMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/index.vue").then(m => m.default || m)
  },
  {
    name: informacoes_45pessoaisfwu15D5CCzMeta?.name ?? "completar-cadastro-informacoes-pessoais",
    path: informacoes_45pessoaisfwu15D5CCzMeta?.path ?? "/completar-cadastro/informacoes-pessoais",
    meta: informacoes_45pessoaisfwu15D5CCzMeta || {},
    alias: informacoes_45pessoaisfwu15D5CCzMeta?.alias || [],
    redirect: informacoes_45pessoaisfwu15D5CCzMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/completar-cadastro/informacoes-pessoais.vue").then(m => m.default || m)
  },
  {
    name: _91partner_93mUPA6rCYvQMeta?.name ?? "crie-sua-conta-partner",
    path: _91partner_93mUPA6rCYvQMeta?.path ?? "/crie-sua-conta/:partner()",
    meta: _91partner_93mUPA6rCYvQMeta || {},
    alias: _91partner_93mUPA6rCYvQMeta?.alias || [],
    redirect: _91partner_93mUPA6rCYvQMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/crie-sua-conta/[partner].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xoGpJEzj3iMeta?.name ?? "crie-sua-conta-b2b-slug",
    path: _91slug_93xoGpJEzj3iMeta?.path ?? "/crie-sua-conta/b2b/:slug()",
    meta: _91slug_93xoGpJEzj3iMeta || {},
    alias: _91slug_93xoGpJEzj3iMeta?.alias || [],
    redirect: _91slug_93xoGpJEzj3iMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/crie-sua-conta/b2b/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934mme6tQNvTMeta?.name ?? "crie-sua-conta-convite-slug",
    path: _91slug_934mme6tQNvTMeta?.path ?? "/crie-sua-conta/convite/:slug()",
    meta: _91slug_934mme6tQNvTMeta || {},
    alias: _91slug_934mme6tQNvTMeta?.alias || [],
    redirect: _91slug_934mme6tQNvTMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/crie-sua-conta/convite/[slug].vue").then(m => m.default || m)
  },
  {
    name: index1Zpa1nNStHMeta?.name ?? "index",
    path: index1Zpa1nNStHMeta?.path ?? "/",
    meta: index1Zpa1nNStHMeta || {},
    alias: index1Zpa1nNStHMeta?.alias || [],
    redirect: index1Zpa1nNStHMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/index.vue").then(m => m.default || m)
  },
  {
    name: confirmacaorLocRdXijHMeta?.name ?? "investir-operationId-confirmacao",
    path: confirmacaorLocRdXijHMeta?.path ?? "/investir/:operationId()/confirmacao",
    meta: confirmacaorLocRdXijHMeta || {},
    alias: confirmacaorLocRdXijHMeta?.alias || [],
    redirect: confirmacaorLocRdXijHMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/confirmacao.vue").then(m => m.default || m)
  },
  {
    name: detalhes9BCZivxgWqMeta?.name ?? "investir-operationId-detalhes",
    path: detalhes9BCZivxgWqMeta?.path ?? "/investir/:operationId()/detalhes",
    meta: detalhes9BCZivxgWqMeta || {},
    alias: detalhes9BCZivxgWqMeta?.alias || [],
    redirect: detalhes9BCZivxgWqMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/detalhes.vue").then(m => m.default || m)
  },
  {
    name: enquadramentoagdQrcW8OaMeta?.name ?? "investir-operationId-enquadramento",
    path: enquadramentoagdQrcW8OaMeta?.path ?? "/investir/:operationId()/enquadramento",
    meta: enquadramentoagdQrcW8OaMeta || {},
    alias: enquadramentoagdQrcW8OaMeta?.alias || [],
    redirect: enquadramentoagdQrcW8OaMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/enquadramento.vue").then(m => m.default || m)
  },
  {
    name: indexiZa2nQKU7ZMeta?.name ?? "investir-operationId",
    path: indexiZa2nQKU7ZMeta?.path ?? "/investir/:operationId()",
    meta: indexiZa2nQKU7ZMeta || {},
    alias: indexiZa2nQKU7ZMeta?.alias || [],
    redirect: indexiZa2nQKU7ZMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/index.vue").then(m => m.default || m)
  },
  {
    name: inicioh0IEDAityJMeta?.name ?? undefined,
    path: inicioh0IEDAityJMeta?.path ?? "/investir/:operationId()/inicio",
    meta: inicioh0IEDAityJMeta || {},
    alias: inicioh0IEDAityJMeta?.alias || [],
    redirect: inicioh0IEDAityJMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/inicio.vue").then(m => m.default || m),
    children: [
  {
    name: confirmar_45contaaKa0B0VJVBMeta?.name ?? "investir-operationId-inicio-confirmar-conta",
    path: confirmar_45contaaKa0B0VJVBMeta?.path ?? "confirmar-conta",
    meta: confirmar_45contaaKa0B0VJVBMeta || {},
    alias: confirmar_45contaaKa0B0VJVBMeta?.alias || [],
    redirect: confirmar_45contaaKa0B0VJVBMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/inicio/confirmar-conta.vue").then(m => m.default || m)
  },
  {
    name: criar_45contatpSp0XvEZmMeta?.name ?? "investir-operationId-inicio-criar-conta",
    path: criar_45contatpSp0XvEZmMeta?.path ?? "criar-conta",
    meta: criar_45contatpSp0XvEZmMeta || {},
    alias: criar_45contatpSp0XvEZmMeta?.alias || [],
    redirect: criar_45contatpSp0XvEZmMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/inicio/criar-conta.vue").then(m => m.default || m)
  },
  {
    name: indexwQEkJeymYRMeta?.name ?? "investir-operationId-inicio",
    path: indexwQEkJeymYRMeta?.path ?? "",
    meta: indexwQEkJeymYRMeta || {},
    alias: indexwQEkJeymYRMeta?.alias || [],
    redirect: indexwQEkJeymYRMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/investir/[operationId]/inicio/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91indicationCode_93pSe4ctxXV6Meta?.name ?? "novo-investidor-indicationCode",
    path: _91indicationCode_93pSe4ctxXV6Meta?.path ?? "/novo-investidor/:indicationCode()",
    meta: _91indicationCode_93pSe4ctxXV6Meta || {},
    alias: _91indicationCode_93pSe4ctxXV6Meta?.alias || [],
    redirect: _91indicationCode_93pSe4ctxXV6Meta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/novo-investidor/[indicationCode].vue").then(m => m.default || m)
  },
  {
    name: _91operationId_93et6i49ZT1NMeta?.name ?? "oportunidades-operationId",
    path: _91operationId_93et6i49ZT1NMeta?.path ?? "/oportunidades/:operationId()",
    meta: _91operationId_93et6i49ZT1NMeta || {},
    alias: _91operationId_93et6i49ZT1NMeta?.alias || [],
    redirect: _91operationId_93et6i49ZT1NMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/oportunidades/[operationId].vue").then(m => m.default || m)
  },
  {
    name: finalizadasbdRwlNPLyvMeta?.name ?? "oportunidades-finalizadas",
    path: finalizadasbdRwlNPLyvMeta?.path ?? "/oportunidades/finalizadas",
    meta: finalizadasbdRwlNPLyvMeta || {},
    alias: finalizadasbdRwlNPLyvMeta?.alias || [],
    redirect: finalizadasbdRwlNPLyvMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/oportunidades/finalizadas.vue").then(m => m.default || m)
  },
  {
    name: historicoOAfcRBy5IBMeta?.name ?? "oportunidades-historico",
    path: historicoOAfcRBy5IBMeta?.path ?? "/oportunidades/historico",
    meta: historicoOAfcRBy5IBMeta || {},
    alias: historicoOAfcRBy5IBMeta?.alias || [],
    redirect: historicoOAfcRBy5IBMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/oportunidades/historico.vue").then(m => m.default || m)
  },
  {
    name: indexmhb1F9TR84Meta?.name ?? "oportunidades",
    path: indexmhb1F9TR84Meta?.path ?? "/oportunidades",
    meta: indexmhb1F9TR84Meta || {},
    alias: indexmhb1F9TR84Meta?.alias || [],
    redirect: indexmhb1F9TR84Meta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/oportunidades/index.vue").then(m => m.default || m)
  },
  {
    name: _91operationId_93wnaFKCCDySMeta?.name ?? "oportunidades-parceiro-operationId",
    path: _91operationId_93wnaFKCCDySMeta?.path ?? "/oportunidades/parceiro/:operationId()",
    meta: _91operationId_93wnaFKCCDySMeta || {},
    alias: _91operationId_93wnaFKCCDySMeta?.alias || [],
    redirect: _91operationId_93wnaFKCCDySMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/oportunidades/parceiro/[operationId].vue").then(m => m.default || m)
  },
  {
    name: indexzgkWs9teFgMeta?.name ?? "originadoras",
    path: indexzgkWs9teFgMeta?.path ?? "/originadoras",
    meta: indexzgkWs9teFgMeta || {},
    alias: indexzgkWs9teFgMeta?.alias || [],
    redirect: indexzgkWs9teFgMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/originadoras/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_936ZJOEzVhxQMeta?.name ?? "painel-ajuda-slug",
    path: _91slug_936ZJOEzVhxQMeta?.path ?? "/painel/ajuda/:slug()",
    meta: _91slug_936ZJOEzVhxQMeta || {},
    alias: _91slug_936ZJOEzVhxQMeta?.alias || [],
    redirect: _91slug_936ZJOEzVhxQMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/ajuda/[slug].vue").then(m => m.default || m)
  },
  {
    name: index5aOWQWmNyPMeta?.name ?? "painel-ajuda",
    path: index5aOWQWmNyPMeta?.path ?? "/painel/ajuda",
    meta: index5aOWQWmNyPMeta || {},
    alias: index5aOWQWmNyPMeta?.alias || [],
    redirect: index5aOWQWmNyPMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/ajuda/index.vue").then(m => m.default || m)
  },
  {
    name: _91operationId_93bMKSAPQXrsMeta?.name ?? "painel-detalhes-investimento-operationId",
    path: _91operationId_93bMKSAPQXrsMeta?.path ?? "/painel/detalhes-investimento/:operationId()",
    meta: _91operationId_93bMKSAPQXrsMeta || {},
    alias: _91operationId_93bMKSAPQXrsMeta?.alias || [],
    redirect: _91operationId_93bMKSAPQXrsMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/detalhes-investimento/[operationId].vue").then(m => m.default || m)
  },
  {
    name: index2kk3qUnr3xMeta?.name ?? "painel",
    path: index2kk3qUnr3xMeta?.path ?? "/painel",
    meta: index2kk3qUnr3xMeta || {},
    alias: index2kk3qUnr3xMeta?.alias || [],
    redirect: index2kk3qUnr3xMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/index.vue").then(m => m.default || m)
  },
  {
    name: index0P5CpZQVvlMeta?.name ?? "painel-indicacao",
    path: index0P5CpZQVvlMeta?.path ?? "/painel/indicacao",
    meta: index0P5CpZQVvlMeta || {},
    alias: index0P5CpZQVvlMeta?.alias || [],
    redirect: index0P5CpZQVvlMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/indicacao/index.vue").then(m => m.default || m)
  },
  {
    name: index9fGNIww1VrMeta?.name ?? "painel-ir",
    path: index9fGNIww1VrMeta?.path ?? "/painel/ir",
    meta: index9fGNIww1VrMeta || {},
    alias: index9fGNIww1VrMeta?.alias || [],
    redirect: index9fGNIww1VrMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/ir/index.vue").then(m => m.default || m)
  },
  {
    name: index0CVsCkGLvpMeta?.name ?? "painel-pendentes",
    path: index0CVsCkGLvpMeta?.path ?? "/painel/pendentes",
    meta: index0CVsCkGLvpMeta || {},
    alias: index0CVsCkGLvpMeta?.alias || [],
    redirect: index0CVsCkGLvpMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/pendentes/index.vue").then(m => m.default || m)
  },
  {
    name: indexjvyJyuS2QxMeta?.name ?? "painel-perfil-alterar-email",
    path: indexjvyJyuS2QxMeta?.path ?? "/painel/perfil/alterar-email",
    meta: indexjvyJyuS2QxMeta || {},
    alias: indexjvyJyuS2QxMeta?.alias || [],
    redirect: indexjvyJyuS2QxMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/alterar-email/index.vue").then(m => m.default || m)
  },
  {
    name: indexFP7aogqixkMeta?.name ?? "painel-perfil-alterar-senha",
    path: indexFP7aogqixkMeta?.path ?? "/painel/perfil/alterar-senha",
    meta: indexFP7aogqixkMeta || {},
    alias: indexFP7aogqixkMeta?.alias || [],
    redirect: indexFP7aogqixkMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/alterar-senha/index.vue").then(m => m.default || m)
  },
  {
    name: indexUQvV4ra46BMeta?.name ?? "painel-perfil-alterar-telefone",
    path: indexUQvV4ra46BMeta?.path ?? "/painel/perfil/alterar-telefone",
    meta: indexUQvV4ra46BMeta || {},
    alias: indexUQvV4ra46BMeta?.alias || [],
    redirect: indexUQvV4ra46BMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/alterar-telefone/index.vue").then(m => m.default || m)
  },
  {
    name: adicionarw8ssa0CLOoMeta?.name ?? "painel-perfil-chave-pix-adicionar",
    path: adicionarw8ssa0CLOoMeta?.path ?? "/painel/perfil/chave-pix/adicionar",
    meta: adicionarw8ssa0CLOoMeta || {},
    alias: adicionarw8ssa0CLOoMeta?.alias || [],
    redirect: adicionarw8ssa0CLOoMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/chave-pix/adicionar.vue").then(m => m.default || m)
  },
  {
    name: indexQqWS4bezM5Meta?.name ?? "painel-perfil-chave-pix",
    path: indexQqWS4bezM5Meta?.path ?? "/painel/perfil/chave-pix",
    meta: indexQqWS4bezM5Meta || {},
    alias: indexQqWS4bezM5Meta?.alias || [],
    redirect: indexQqWS4bezM5Meta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/chave-pix/index.vue").then(m => m.default || m)
  },
  {
    name: editarHQBKFUggjPMeta?.name ?? "painel-perfil-dados-bancarios-id-editar",
    path: editarHQBKFUggjPMeta?.path ?? "/painel/perfil/dados-bancarios/:id()/editar",
    meta: editarHQBKFUggjPMeta || {},
    alias: editarHQBKFUggjPMeta?.alias || [],
    redirect: editarHQBKFUggjPMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/[id]/editar.vue").then(m => m.default || m)
  },
  {
    name: adicionarMrHGqbz5riMeta?.name ?? "painel-perfil-dados-bancarios-adicionar",
    path: adicionarMrHGqbz5riMeta?.path ?? "/painel/perfil/dados-bancarios/adicionar",
    meta: adicionarMrHGqbz5riMeta || {},
    alias: adicionarMrHGqbz5riMeta?.alias || [],
    redirect: adicionarMrHGqbz5riMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/adicionar.vue").then(m => m.default || m)
  },
  {
    name: indexVlWSJu2CJxMeta?.name ?? "painel-perfil-dados-bancarios",
    path: indexVlWSJu2CJxMeta?.path ?? "/painel/perfil/dados-bancarios",
    meta: indexVlWSJu2CJxMeta || {},
    alias: indexVlWSJu2CJxMeta?.alias || [],
    redirect: indexVlWSJu2CJxMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/index.vue").then(m => m.default || m)
  },
  {
    name: index1Ik7GdNuDpMeta?.name ?? "painel-perfil-dados-patrimoniais",
    path: index1Ik7GdNuDpMeta?.path ?? "/painel/perfil/dados-patrimoniais",
    meta: index1Ik7GdNuDpMeta || {},
    alias: index1Ik7GdNuDpMeta?.alias || [],
    redirect: index1Ik7GdNuDpMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/dados-patrimoniais/index.vue").then(m => m.default || m)
  },
  {
    name: index81jC0vlSrTMeta?.name ?? "painel-perfil-dados-pessoais",
    path: index81jC0vlSrTMeta?.path ?? "/painel/perfil/dados-pessoais",
    meta: index81jC0vlSrTMeta || {},
    alias: index81jC0vlSrTMeta?.alias || [],
    redirect: index81jC0vlSrTMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/dados-pessoais/index.vue").then(m => m.default || m)
  },
  {
    name: indexHO7j90hHJUMeta?.name ?? "painel-perfil-desativar-conta",
    path: indexHO7j90hHJUMeta?.path ?? "/painel/perfil/desativar-conta",
    meta: indexHO7j90hHJUMeta || {},
    alias: indexHO7j90hHJUMeta?.alias || [],
    redirect: indexHO7j90hHJUMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/desativar-conta/index.vue").then(m => m.default || m)
  },
  {
    name: indexUDDNk7qAuHMeta?.name ?? "painel-perfil-documentos",
    path: indexUDDNk7qAuHMeta?.path ?? "/painel/perfil/documentos",
    meta: indexUDDNk7qAuHMeta || {},
    alias: indexUDDNk7qAuHMeta?.alias || [],
    redirect: indexUDDNk7qAuHMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/documentos/index.vue").then(m => m.default || m)
  },
  {
    name: indexaljlzCyntLMeta?.name ?? "painel-perfil-endereco",
    path: indexaljlzCyntLMeta?.path ?? "/painel/perfil/endereco",
    meta: indexaljlzCyntLMeta || {},
    alias: indexaljlzCyntLMeta?.alias || [],
    redirect: indexaljlzCyntLMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/endereco/index.vue").then(m => m.default || m)
  },
  {
    name: indexf4PjRzONlDMeta?.name ?? "painel-perfil",
    path: indexf4PjRzONlDMeta?.path ?? "/painel/perfil",
    meta: indexf4PjRzONlDMeta || {},
    alias: indexf4PjRzONlDMeta?.alias || [],
    redirect: indexf4PjRzONlDMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/perfil/index.vue").then(m => m.default || m)
  },
  {
    name: _91assetSymbol_93tgLKiPA0BGMeta?.name ?? "painel-wallet-assetSymbol",
    path: _91assetSymbol_93tgLKiPA0BGMeta?.path ?? "/painel/wallet/:assetSymbol()",
    meta: _91assetSymbol_93tgLKiPA0BGMeta || {},
    alias: _91assetSymbol_93tgLKiPA0BGMeta?.alias || [],
    redirect: _91assetSymbol_93tgLKiPA0BGMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/wallet/[assetSymbol].vue").then(m => m.default || m)
  },
  {
    name: depositoOs5A1rL3w6Meta?.name ?? "painel-wallet-deposito",
    path: depositoOs5A1rL3w6Meta?.path ?? "/painel/wallet/deposito",
    meta: depositoOs5A1rL3w6Meta || {},
    alias: depositoOs5A1rL3w6Meta?.alias || [],
    redirect: depositoOs5A1rL3w6Meta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/wallet/deposito.vue").then(m => m.default || m)
  },
  {
    name: index9TVTykzdUdMeta?.name ?? "painel-wallet",
    path: index9TVTykzdUdMeta?.path ?? "/painel/wallet",
    meta: index9TVTykzdUdMeta || {},
    alias: index9TVTykzdUdMeta?.alias || [],
    redirect: index9TVTykzdUdMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/wallet/index.vue").then(m => m.default || m)
  },
  {
    name: resgatemhKenKNZjRMeta?.name ?? "painel-wallet-resgate",
    path: resgatemhKenKNZjRMeta?.path ?? "/painel/wallet/resgate",
    meta: resgatemhKenKNZjRMeta || {},
    alias: resgatemhKenKNZjRMeta?.alias || [],
    redirect: resgatemhKenKNZjRMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/painel/wallet/resgate.vue").then(m => m.default || m)
  },
  {
    name: index6SXqo7ef9bMeta?.name ?? "parceiros",
    path: index6SXqo7ef9bMeta?.path ?? "/parceiros",
    meta: index6SXqo7ef9bMeta || {},
    alias: index6SXqo7ef9bMeta?.alias || [],
    redirect: index6SXqo7ef9bMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/parceiros/index.vue").then(m => m.default || m)
  },
  {
    name: index7h0VZMdpX6Meta?.name ?? "politica-de-privacidade",
    path: index7h0VZMdpX6Meta?.path ?? "/politica-de-privacidade",
    meta: index7h0VZMdpX6Meta || {},
    alias: index7h0VZMdpX6Meta?.alias || [],
    redirect: index7h0VZMdpX6Meta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/politica-de-privacidade/index.vue").then(m => m.default || m)
  },
  {
    name: indexUS5oF0UzPHMeta?.name ?? "quem-somos",
    path: indexUS5oF0UzPHMeta?.path ?? "/quem-somos",
    meta: indexUS5oF0UzPHMeta || {},
    alias: indexUS5oF0UzPHMeta?.alias || [],
    redirect: indexUS5oF0UzPHMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/quem-somos/index.vue").then(m => m.default || m)
  },
  {
    name: index53FOwv14JiMeta?.name ?? "remocao-de-conta",
    path: index53FOwv14JiMeta?.path ?? "/remocao-de-conta",
    meta: index53FOwv14JiMeta || {},
    alias: index53FOwv14JiMeta?.alias || [],
    redirect: index53FOwv14JiMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/remocao-de-conta/index.vue").then(m => m.default || m)
  },
  {
    name: _91signupCode_93nIl58PbsN0Meta?.name ?? "seja-investidor-signupCode",
    path: _91signupCode_93nIl58PbsN0Meta?.path ?? "/seja-investidor/:signupCode()",
    meta: _91signupCode_93nIl58PbsN0Meta || {},
    alias: _91signupCode_93nIl58PbsN0Meta?.alias || [],
    redirect: _91signupCode_93nIl58PbsN0Meta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/seja-investidor/[signupCode].vue").then(m => m.default || m)
  },
  {
    name: indexZBfm4VZT0aMeta?.name ?? "termos-de-uso",
    path: indexZBfm4VZT0aMeta?.path ?? "/termos-de-uso",
    meta: indexZBfm4VZT0aMeta || {},
    alias: indexZBfm4VZT0aMeta?.alias || [],
    redirect: indexZBfm4VZT0aMeta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/termos-de-uso/index.vue").then(m => m.default || m)
  },
  {
    name: indexNaqdd6cZB9Meta?.name ?? "tokenizacao",
    path: indexNaqdd6cZB9Meta?.path ?? "/tokenizacao",
    meta: indexNaqdd6cZB9Meta || {},
    alias: indexNaqdd6cZB9Meta?.alias || [],
    redirect: indexNaqdd6cZB9Meta?.redirect,
    component: () => import("/codebuild/output/src3468967218/src/hcp-web-platform/pages/tokenizacao/index.vue").then(m => m.default || m)
  }
]